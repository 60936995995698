<template>
  <section class="editor-container">
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    <div class="editor-footer df">
      <p>words: {{ words.length }}</p>
      <p>charts: {{ clearText.length }}</p>
<!--      <p>min charts: 100</p>-->
<!--      <p>max charts: 15000</p>-->
    </div>
  </section>
</template>

<script>
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Font from '@ckeditor/ckeditor5-font/src/font';
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';

export default {
  name: "AEditor",
  props: ['value'],
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.value || '',
      editorConfig: {
        plugins: [
          ListStyle,
          Font,
          Underline,
          Strikethrough,
          BoldPlugin,
          ItalicPlugin,
          EssentialsPlugin,
          ParagraphPlugin
        ],
        toolbar: {
          items: [
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontSize',
            'bulletedList', 'numberedList'
          ]
        }
      }
    }
  },
  watch: {
    editorData(text){
      this.$emit('input', text);
    },
    value(text, prevText) {
      if(text !== prevText){
        this.editorData = text;
      }
    }
  },
  computed: {
    clearText() {
      let span = document.createElement('span');
      span.innerHTML = this.editorData.replace('</li>', ' </li>');
      return span.textContent || span.innerText;
    },
    words() {
      const expressions = ['<strong>', '</strong>', '<i>', '</i>', '<s>', '</s>'];

      let cont = this.editorData;
      expressions.forEach(e => {
        cont = cont.replaceAll(e, '');
      });
      cont = cont.replace(/<[^>]*>/g,' ').replace(/&nbsp;/gi, '')
        .replace(/\s{2,}/g, ' ').trim();
      return cont.split(' ');
    }
  },
}
</script>

<style lang="scss">
@import "@/styles/components/editor";
</style>
